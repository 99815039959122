import React from 'react';

const base = 'terminal-main-items';

export default function MainItems({
    items,
    sections,
    onAddToCart
}) {
    let imageList = [
        "https://image.popcontent.link/content-card/rank/rank-1.png",
        "https://image.popcontent.link/content-card/rank/rank-2.png",
        "https://image.popcontent.link/content-card/rank/rank-3.png",
        "https://image.popcontent.link/content-card/rank/rank-4.png",
        "https://image.popcontent.link/content-card/rank/rank-5.png"
    ]

    const scrollToSection = (sectionId) => {
        if (!sectionId) {
            return;
        }
        const section = document.getElementById(sectionId);
        const offset = 60; // 60px above the section
        const sectionPosition = section.getBoundingClientRect().top;
        const scrollPosition = document.documentElement.scrollTop;
        window.scrollTo({
          top: sectionPosition + scrollPosition - offset,
          behavior: 'smooth'
        });
      }

    const fetchItemAndAddToCart = (itemId) => {
        const item = items.filter((item) => item.menu_item_id == itemId)[0];
        onAddToCart(item, false);
    }

    return (
        <div className={`${base}`} style={{marginBottom : "20rem"}}>
            {sections.map(section => (
                section.type == "Masthead" ? (
                    <div className={`${base}__masthead display--flex`} key={section.id}>
                        <div style={{maxWidth: '50%'}}>
                            <p className="heading">
                                {section.text}
                            </p>
                            <p className="sub_heading">
                                {section.sub_text}
                            </p>
                            {section.cta_text && (
                                section.button_type == "Open_Link" ? (
                                    <a href = {section.target} >
                                        <button className="button">
                                                {section.cta_text}
                                        </button>
                                    </a>
                                ) : section.button_type == "Lead_To_Menu_Item" ? (
                                    <button className="button" onClick={() => fetchItemAndAddToCart(section.target)}>
                                            {section.cta_text}
                                    </button>
                                ) : (
                                    <button className="button" onClick={() => scrollToSection(section.target)}>
                                            {section.cta_text}
                                    </button>
                                )
                            )}
                        </div>
                        {section.button_type == "Open_Link" ? (
                            <a href = {section.target} >
                                <div className="masthead_image" style={{backgroundImage : `url(${section.image})`}}/>
                            </a>
                        ) : section.button_type == "Lead_To_Menu_Item" ? (
                            <div className="masthead_image" style={{backgroundImage : `url(${section.image})`}} onClick={() => fetchItemAndAddToCart(section.target)}/>
                        ) : (
                            <div className="masthead_image" style={{backgroundImage : `url(${section.image})`}} onClick={() => scrollToSection(section.target)}/>
                        )
                        }
                    </div>
                ) : (
                    (section.top_item_category && section.items && section.items.length > 0) ? (
                        <div className={`${base}__top_item_category`} id={section.id} key={section.id}>
                            <div className={`${base}__item_card_heading`}>
                                {section.text}
                            </div>
                            <div className={`${base}__item_cards`}>
                                {section.items.map((item, index) => (
                                    <div className="item_card display--flex" style={{marginLeft: "1rem"}}  key={item.menu_item_id}>
                                        <img className="ranking_image" src={imageList[index]} />
                                        <div style={{position: 'relative'}}
                                            onClick={() => onAddToCart(item, false)}
                                        >
                                            <p className="item_title" style={item.display_mode == "White" ? {color: 'black'} : {color: 'white'}}>{item.title_bold}</p>
                                            <div className="item_image" style={{backgroundImage : `url(${item.image})`}}/>
                                            <div className="item_price" style={item.display_mode == "White" ? {color: 'black'} : {color: 'white'}}>
                                                <p style={item.special_offer_price ? {textDecoration: 'line-through'} : {}}>{item.price}</p>
                                                <p style={{marginLeft: '0.25rem'}}>{item.special_offer_price}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        (section.card_size == "Large" && section.items && section.items.length > 0) ? (
                            <div className={`${base}__large_menu_line`} id={section.id} key={section.id}>
                                <div className={`${base}__item_card_heading`}>
                                    {section.text}
                                </div>
                                <div className="item_cards display--flex">
                                    {section.items.map((item) => (
                                        <div className="display--flex" style={{marginLeft: "1rem"}} key={item.menu_item_id}>
                                            <div style={{position: 'relative'}} onClick={() => onAddToCart(item, false)} >
                                                <p className="item_title" style={item.display_mode == "White" ? {color: 'black'} : {color: 'white'}}>{item.title_bold}</p>
                                                <div className="item_image" style={{backgroundImage : `url(${item.image})`}}/>
                                                <div className="item_price" style={item.display_mode == "White" ? {color: 'black'} : {color: 'white'}}>
                                                    <p style={item.special_offer_price ? {textDecoration: 'line-through'} : {}}>{item.price}</p>
                                                    <p style={{marginLeft: '0.25rem'}}>{item.special_offer_price}</p>
                                                </div>                                            
                                            </div>                                        
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (section.card_size == "Medium" && section.items && section.items.length > 0) ? (
                            <div className={`${base}__medium_menu_line`} id={section.id} key={section.id}>
                                <div className={`${base}__item_card_heading`}>
                                    {section.text}
                                </div>
                                <div className={`${base}__item_cards`}>
                                    {section.items.map((item) => (
                                        <div className="display--flex" style={{marginLeft: "1rem"}} key={item.menu_item_id}>
                                            <div style={{position: 'relative'}} onClick={() => onAddToCart(item, false)} >
                                                <p className="item_title" style={item.display_mode == "White" ? {color: 'black'} : {color: 'white'}}>{item.title_bold}</p>
                                                <div className="item_image" style={{backgroundImage : `url(${item.image})`}}/>
                                                <div className="item_price" style={item.display_mode == "White" ? {color: 'black'} : {color: 'white'}}>
                                                    <p style={item.special_offer_price ? {textDecoration: 'line-through'} : {}}>{item.price}</p>
                                                    <p style={{marginLeft: '0.25rem'}}>{item.special_offer_price}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            (section.items && section.items.length > 0) && (
                                <div className={`${base}__small_menu_line`} id={section.id} key={section.id}>
                                    <div className={`${base}__item_card_heading`}>
                                        {section.text}
                                    </div>
                                    <div className={`${base}__item_cards`}>
                                        {section.items.map((item) => (
                                            <div className="display--flex" style={{marginLeft: "1rem"}} key={item.menu_item_id}>
                                                <div style={{position: 'relative'}} onClick={() => onAddToCart(item, false)} >
                                                    <p className="item_title" style={item.display_mode == "White" ? {color: 'black'} : {color: 'white'}}>{item.title_bold}</p>
                                                    <div className="item_image" style={{backgroundImage : `url(${item.image})`}}/>
                                                    <div className="item_price" style={item.display_mode == "White" ? {color: 'black'} : {color: 'white'}}>
                                                        <p style={item.special_offer_price ? {textDecoration: 'line-through'} : {}}>{item.price}</p>
                                                        <p style={{marginLeft: '0.25rem'}}>{item.special_offer_price}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )
                        )
                    )
                )
            ))}
        </div>
    );
}
