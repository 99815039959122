import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import LuckyWheel from './LuckyWheel';
import { onDocumentIdle } from '../../../services/domUtils';
import { GetQueryParamFromUrl } from '../../../services/url';
import Modal from '../../core/Modal';
import PopupRewardCode from './PopupRewardCode';
const base = 'terminal-lucky-wheel-screen';
@inject('terminalMenuStore')
@observer
export default class extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        const { terminalMenuStore } = this.props;
        // terminalMenuStore.setShowPopup('asd');
        onDocumentIdle({
            seconds: 30,
            callback: this.onIdleTimeReached,
            checkEverySeconds: 10,
        });
    }

    onIdleTimeReached = () => {
        const { terminalMenuStore } = this.props;
        let orderType = GetQueryParamFromUrl('orderType');
        if (terminalMenuStore.showingPopup != 'reward-code') {
            terminalMenuStore.showSplashScreenActions(orderType);
            terminalMenuStore.setOrderTypeIfExistsAndReloadMenu(orderType);
        }
    };

    rewardRedeemCallback = () => {
        const { terminalMenuStore } = this.props;

        if (terminalMenuStore.luckyWheelReward && terminalMenuStore.luckyWheelReward.sku) {
            terminalMenuStore.setShowPopup('reward-code');
            // setShowPopup('reward-code');
            terminalMenuStore.rewardRedeemCode(terminalMenuStore.luckyWheelReward.sku);
        }
    };

    handleClosePopup = () => {
        const { terminalMenuStore } = this.props;
        terminalMenuStore.setShowPopup('');
    };

    setArrowDirection = (direction) => {
        if (direction == 'left') {
            return 'https://image.popcontent.link/Left+Arrow+.png';
        } else if (direction == 'right') {
            return 'https://image.popcontent.link/Right+Arrow+.png';
        } else {
            return '/upward-arrow.png';
        }
    };

    render() {
        const {
            terminalMenuStore: {
                luckyWheelReward,
                customerName,
                arrowDirection,
                showingPopup,
                showSplashScreenActions,
                paymentType,
                showLuckyWheel,
            },
        } = this.props;

        console.log(showLuckyWheel, 'show luck wheel now');
        const isPaidPaymentMethod = paymentType == 'Kiosk_Ewallet' || paymentType == 'Card';
        return (
            <div className={base}>
                {showLuckyWheel ? (
                    <LuckyWheel rewardRedeemCallback={this.rewardRedeemCallback} />
                ) : (
                    luckyWheelReward && (
                        <>
                            <Modal
                                showModal={Boolean(showingPopup)}
                                showCloseIcon={false}
                                disableScrollingOnMobile={true}
                            >
                                <PopupRewardCode />
                            </Modal>
                            <div className={`${base}__content`}>
                                {!isPaidPaymentMethod && arrowDirection == 'up' ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {paymentType != 'Kiosk_Ewallet' ? (
                                            <img
                                                src={'/upward-arrow.png'}
                                                className={`${base}__arrow`}
                                                alt="arrow image"
                                            />
                                        ) : null}
                                        <div>
                                            <div
                                                style={{
                                                    fontSize: '64px',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                PROCEED HERE TO PAY
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        {!isPaidPaymentMethod ? (
                                            <img
                                                style={{ marginBottom: '10px' }}
                                                src={this.setArrowDirection(arrowDirection)}
                                                alt="arrow image"
                                            />
                                        ) : null}
                                        <div>
                                            <div
                                                style={{
                                                    fontSize: '64px',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {!isPaidPaymentMethod
                                                    ? intl.get('terminal.popupOrderPaidBy.kioskWallet')
                                                    : intl.get('terminal.popupOrderPaidBy.cash')}
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <button
                                    className={`${base}__start-new-order`}
                                    onClick={() => showSplashScreenActions()}
                                >
                                    {intl.get('terminal.popupConfirm.rewardWin.startOrder')}
                                </button>
                            </div>
                        </>
                    )
                )}
            </div>
        );
    }
}
