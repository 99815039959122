import React from 'react';
import { inject, observer } from 'mobx-react';

import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import { GetQueryParamFromUrl } from '../../../services/url';
import Loading from '../Loading';

const base = 'terminal-header';

@inject('terminalMenuStore')
@inject('terminalCartStore')

@observer
export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSectionId: null
        };
        this.navbarRef = React.createRef();
        this.tabRefs = {};
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const { disableScrollHandling } = this.state;
        
        // Disable handleScroll when user clicks a tab
        if (disableScrollHandling) {
            return;  
        }

        const { sections } = this.props.terminalMenuStore;
        let activeSectionId = null;

        sections.forEach(section => {
            const sectionElement = document.getElementById(section.id);
            if (sectionElement) {
                const rect = sectionElement.getBoundingClientRect();
                const offset = 60; // Same as the offset used in scrollToSection
                if (rect.top - offset <= 0 && rect.bottom - offset >= 0) {
                    activeSectionId = section.id;
                }
            }
        });

        if (activeSectionId !== this.state.activeSectionId) {
            this.setState({ activeSectionId }, () => {
                this.scrollTabIntoView(activeSectionId);
            });
        }
    };

    scrollTabIntoView = (sectionId) => {
        const tabElement = this.tabRefs[sectionId];
        const navbarElement = this.navbarRef.current;

        if (tabElement && navbarElement) {
            const tabRect = tabElement.getBoundingClientRect();
            const navbarRect = navbarElement.getBoundingClientRect();

            // Check if the tab is not fully visible in the navbar
            if (tabRect.left < navbarRect.left || tabRect.right > navbarRect.right) {
                navbarElement.scrollTo({
                    left: tabElement.offsetLeft - navbarElement.offsetLeft,
                    behavior: 'smooth',
                });
            }
        }
    };
    
    selectUIView = (view) => {
        const {
            terminalMenuStore: { setItemsUIView },
        } = this.props;
        setItemsUIView(view);
    };

    handleShowSplash = () => {
        const { terminalMenuStore, terminalCartStore } = this.props;
        let orderType = GetQueryParamFromUrl('orderType');
        terminalMenuStore.showSplashScreenActions(orderType);
        terminalCartStore.toggleCombo = false;
        terminalMenuStore.setCouponTxt('');
        terminalMenuStore.setCouponDisable(false);
        terminalMenuStore.setCouponErrMessage(null);
        terminalMenuStore.setOrderTypeIfExistsAndReloadMenu(orderType);
    };

    handleHelpBtnClick = () => {
        const {
            terminalMenuStore: { mobileScreens, setShowMobileScreen },
        } = this.props;
        setShowMobileScreen(mobileScreens.HELP);
    };

    scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
    
        if (!section) {
            const {
                terminalMenuStore: { itemTypes },
            } = this.props;
            this.selectUIView(itemTypes.MAINS)
            setTimeout(() => {
                this.scrollToSection(sectionId);
            }, 100);
            return;
        }
        // Temporarily disable handleScroll
        this.setState({ disableScrollHandling: true });

        const offset = 60; // 60px above the section
        const sectionPosition = section.getBoundingClientRect().top;
        const scrollPosition = document.documentElement.scrollTop;

        window.scrollTo({
            top: sectionPosition + scrollPosition - offset,
            behavior: 'smooth',
        });

        this.setState({ activeSectionId: sectionId }, () => {
            this.scrollTabIntoView(sectionId);
        });

        clearTimeout(this.scrollTimeout);
        this.scrollTimeout = setTimeout(() => {
            this.setState({ disableScrollHandling: false });
        }, 1000);
    };

    render() {
        const {
            terminalMenuStore: {
                itemTypes,
                itemsUIView,
                terminalInfo,
                showSplashScreen,
                showLuckyWheelScreen,
                hubCode,
                showMobileScreen,
                mobileScreens,
                sections
            },
            onHeaderClick,
        } = this.props;
        const { activeSectionId } = this.state;
        const isSmallScreen = window.matchMedia(SMALL_SCREEN_MEDIA).matches;
        const isHelpScreenOpen = showMobileScreen === mobileScreens.HELP;
        const outletName = terminalInfo.data && terminalInfo.data.address.name;
        const notShowHeaderOnIpad = showSplashScreen || showLuckyWheelScreen;

        if (!sections) {
            return <Loading />
        }

        let sectionTabs = sections.filter((section) => section.type == "Category" && section.items.length > 0);

        if (isSmallScreen) {
            return (
                <div className={base}>
                    <div className={`${base}__logo`}>
                        <img className={`${base}__logo_img`} src={process.env.PUBLIC_URL + '/ms-icon-150x150.png'} />
                    </div>
                    {outletName && (
                        <div className={`${base}__outlet_name_cont`}>
                            {outletName.includes('@') ? (
                                <p className={`${base}__outlet_name`}>
                                    {outletName.split('@')[0]}@ <br />
                                    {outletName.split('@')[1]}
                                </p>
                            ) : (
                                <p className={`${base}__outlet_name`}>
                                    Pop Meals@ <br />
                                    {outletName}
                                </p>
                            )}
                        </div>
                    )}

                    <img
                        onClick={this.handleHelpBtnClick}
                        className={`${base}__help_btn ${isHelpScreenOpen ? 'help-screen-open' : ''}`}
                        src="https://image.popcontent.link/help_icon.svg"
                        alt="help icon"
                    />
                </div>
            );
        }

        return (
            !notShowHeaderOnIpad && (
                <div className={`${base}`} id={base}>
                    <img
                        src={process.env.PUBLIC_URL + '/pop_logo.png'}
                        style={{marginTop: '5px', marginRight: '1rem'}}
                    />
                    <div className={`${base}__item_types`}>
                        <div className="item_type_category" ref={this.navbarRef}>
                            {sectionTabs.map((section, index) => (
                                <div key={section.id}>
                                    <span ref={el => this.tabRefs[section.id] = el}
                                        onClick={() => this.scrollToSection(section.id)} 
                                        className={`text-item ${activeSectionId === section.id ? 'selected' : 'not-selected'}`}
                                        style={index == 0 ? {marginLeft: '0.25rem'} : 
                                            (index == sectionTabs.length - 1) ? {marginRight: '0.5rem'} : {}}
                                        >
                                            {section.top_item_category ? "Top 5" : section.text}
                                    </span>
                                </div>
                            ))}
                        </div>

                        <div
                            onClick={() => this.selectUIView(itemTypes.DEALS)}
                            className={`item_type ${itemsUIView === itemTypes.DEALS ? 'selected' : 'not-selected'}`}
                        >
                            <span className="text-item">Family Combos</span>
                        </div>
                        <div
                            onClick={() => this.selectUIView(itemTypes.COUPONS)}
                            className={`item_type ${itemsUIView === itemTypes.COUPONS ? 'selected' : 'not-selected'}`}
                        >
                            <span className="text-item">Coupon</span>
                        </div>
                    </div>
                </div>
            )
        );
    }
}
